import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Espècie de color totalment blanca que en temps sec pren una lleugera coloració groguenca. Consisteix amb un capell ondulat, de marge enrotllat, de tacte vellutat i un peu irregular i gruixut. Les espores són blanques en massa, el·líptiques, de 4,5-5,2 x 3-3,7 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      